/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { NodeSwVersion, UPDATE_STATE } from '../models';
import { Meta } from '@angular/platform-browser';
import { App, AppInfo } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class EdoVersionService {
  private systemversion: string;
  private macaddress: string;
  private license: string;
  private roughsystemversion: string;
  private usbinterfaceversion: string;

  private MINUM_CONTROLLER_VERSION = '3.2';

  constructor(
    private meta: Meta
  ) {}

  get systemVersion(): string {
    return this.systemversion;
  }

  get macAddress(): string {
    return this.macaddress;
  }

  get edoLicense(): string {
    return this.license;
  }

  get roughSystemVersion(): string {
    return this.roughsystemversion;
  }

  get usbInterfaceVersion(): string {
    return this.usbinterfaceversion;
  }

  get minimumControllerVersion(): string {
    return this.MINUM_CONTROLLER_VERSION;
  }

  async getVersionNumber() {
    try {
      const appInfo: AppInfo = await App.getInfo();
      return appInfo.version;
    } catch (error) {
      return this.getVersionFromHtml();
    }
  }

  /**
   * check the sw version of the e.DO cube or e.DO robot and return an UPDATE_STATE.
   *
   * @param swVersionNodes the initial info nodes that e.DO pass through machine_bridge_sw_version_srv
   * @returns TO_UPDATE if e.DO needs an update, TO_NOT_UPDATE if e.DO don't needs an update, TO_APP_UPDATE the application needs an update
   */
  checkSoftwareVersion(swVersionNodes: NodeSwVersion[]): UPDATE_STATE{
    if (swVersionNodes.length < 4) {
      // is not a good version, e.DO need an update
      if (swVersionNodes.length === 1 && swVersionNodes[0].id === 255) {
        this.systemversion = this.createSystemVersion(swVersionNodes[0]);
      }
      return UPDATE_STATE.TO_UPDATE;
    }

    const nodeSystemVersion: NodeSwVersion = swVersionNodes[1]; // id 255
    const nodeMacAdd: NodeSwVersion = swVersionNodes[2]; // id 253
    const nodeLic: NodeSwVersion = swVersionNodes[3]; // id 254

    if (!nodeSystemVersion || !nodeMacAdd || !nodeLic) {
      return UPDATE_STATE.TO_UPDATE;
    }

    if (nodeSystemVersion.id === 255) {
      this.systemversion = this.createSystemVersion(nodeSystemVersion);
      this.roughsystemversion = nodeSystemVersion.version;
      this.usbinterfaceversion = swVersionNodes[0].version;
      this.macaddress = nodeMacAdd.version;
      const systemVersionArray: number[] = this.systemversion.split('.').map(v => Number(v));
      const controlVersion: number[] = this.MINUM_CONTROLLER_VERSION.split('.').map(v => Number(v));
      // if the system version is lower than minimum system version to work, the e.DO system need an update
      return this.checkVersionIsGreaterThan(systemVersionArray, controlVersion) || this.checkVersionIsEqualThan(systemVersionArray, controlVersion)
       ? UPDATE_STATE.TO_NOT_UPDATE : UPDATE_STATE.TO_UPDATE;
    } else {
      return UPDATE_STATE.TO_UPDATE;
    }
  }

  private getVersionFromHtml(): string{
    const viewport = this.meta.getTag('name=version');
    return viewport.content;
  }

  private createSystemVersion(nodeSystemVersion: NodeSwVersion): string{
    const cleanedVer = nodeSystemVersion.version.split('_');
    if (cleanedVer[1] != null) {
      const verNumbers = cleanedVer[1].split('.', 2);
      return `${verNumbers[0]}.${verNumbers[1]}`;
    }
    return null;
  }

  private checkVersionIsEqualThan(versionA: number[], versionB: number[]): boolean{
    return this.checkVersionIsEqualThanSupp(versionA, versionB, 0);
  }

  private checkVersionIsEqualThanSupp(versionA: number[], versionB: number[], index: number): boolean{
    if (index > versionA.length - 1) {
      return true;
    }
    return this.compare(versionA[index], versionB[index]) === 0 && this.checkVersionIsEqualThanSupp(versionA, versionB, index + 1);
  }

  private checkVersionIsGreaterThan(versionA: number[], versionB: number[]): boolean{
    return this.checkVersionIsGreaterThanSupp(versionA, versionB, 0);
  }

  private checkVersionIsGreaterThanSupp(versionA: number[], versionB: number[], index: number): boolean{
    if (index > versionA.length - 1) {
      return false;
    }
    const compareResult: number = this.compare(versionA[index], versionB[index]);
    return compareResult > 0 ? true : compareResult < 0 ? false : this.checkVersionIsGreaterThanSupp(versionA, versionB, index+1);
  }

  /**
   *
   * @param a number to compare
   * @param b number to compare
   * @returns 0 if a and b are equals, -1 if a < b, 1 if a > b
   */
  private compare(a: number, b: number): number{
    return Number(a) === Number(b) ? 0 : Number(a) < Number(b) ? -1 : 1;
  }
}
