/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';
import { ec } from 'elliptic';

enum LicenseType {
  FULL = 'full',
  PLUGINS = 'plugins',
  VIRTUAL = 'virtual',
  NONE = 'no license'
}

type LicenseObject = {
  mac: string;
  license: string;
};

const EC_CURVE = 'secp256k1';
// eslint-disable-next-line max-len
const PUB_KEY = '04329596e0428a7767f507d28cd924c29617ea5d6cdd8cfa280fcaa81790ec884738f197acb7f555811fae25d33756f6973fc9ec2de8b24db55598a5703a18487d';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private pubKey: ec.KeyPair;

  constructor() {
    const ecObj = new ec(EC_CURVE);
    this.pubKey = ecObj.keyFromPublic(PUB_KEY, 'hex');
   }

  getLicenseType(robotMac: string, robotLicense: string): LicenseType {
    if (robotLicense === 'no license') { return LicenseType.NONE; }
    for (const key in LicenseType) {
      if (LicenseType.hasOwnProperty(key)) {
        const hash = this.createLicenseSHA(robotMac.toLowerCase(), LicenseType[key]);
        let signArray;
        try {
          signArray = JSON.parse(robotLicense);
        } catch (error) {
          signArray = robotLicense;
        }
        if (this.pubKey.verify(hash, signArray)) {
          return LicenseType[key];
        }
      }
    }
    return LicenseType.NONE;
  }

  isValidLicense(license: LicenseType) {
    return license === LicenseType.FULL || license === LicenseType.VIRTUAL;
  }

  private createLicenseSHA(mac: string, license: string): string {
    const licenseObj: LicenseObject = {
      mac,
      license
    };
    const stringFullLicense = JSON.stringify(licenseObj);
    const hashFullLicense = sha256(stringFullLicense);
    return hashFullLicense;
  }
}
