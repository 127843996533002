/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { VirtualBrush } from '../virtual-brush/virtual-brush.model';
import { TranslateService } from '@ngx-translate/core';
import { Lang, AVAILABLE_LANGUAGES } from './language.service';

//TODO: improve this class, define storage interface and then implement every storage class where need
// e.g. pwaService implements EdoStorageService (think about init, maybe edoStorage should be abstract)
@Injectable({
  providedIn: 'root'
})
export class EdoStorageService {
  private readonly IP_KEY = 'ip';
  private readonly BRUSH_KEY = 'brush';
  private readonly LANG_KEY = 'lang';
  private readonly COLOR_SCHEME_KEY = 'color_scheme';
  private readonly SYSTEM_THEME_KEY = 'system_theme';
  private readonly DEBUG_KEY = 'physics_debug';
  private readonly pwaKeyStorage = 'pwa_installed';

  private myStorage: Storage;

  constructor(
    private storage: Storage,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe(value => {
      const lang: Lang = AVAILABLE_LANGUAGES.find(l => l.code === value.lang);
      this.storage.set(this.LANG_KEY, lang);
    });
  }

  set virtualBrush(value: VirtualBrush) {
    this.storage.set(this.BRUSH_KEY, value);
  }

  set ip(value: string) {
    this.storage.set(this.IP_KEY, value);
  }

  set darkMode(value: boolean) {
    this.storage.set(this.COLOR_SCHEME_KEY, value);
  }

  set systemTheme(value: boolean) {
    this.storage.set(this.SYSTEM_THEME_KEY, value);
  }

  set debugMode(value: boolean) {
    this.storage.set(this.DEBUG_KEY, value);
  }

  set pwaInstalled(value: boolean) {
    this.storage.set(this.pwaKeyStorage, value);
  }

  async getIp(): Promise<string> {
    return await this.storage.get(this.IP_KEY);
  }

  async getVirtualBrush() {
    const brush: VirtualBrush = await this.storage.get(this.BRUSH_KEY) || new VirtualBrush();
    return new VirtualBrush(brush.color, brush.weight);
  }

  async getLang(): Promise<Lang> {
    return await this.storage.get(this.LANG_KEY) as Lang;
  }

  async isPwaInstalledInStorage(): Promise<boolean> {
    return await this.myStorage?.get(this.pwaKeyStorage);
  }

  async isDarkMode(): Promise<boolean> {
    if (await this.isSystemTheme()) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      return await this.storage.get(this.COLOR_SCHEME_KEY) === null ?
      window.matchMedia('(prefers-color-scheme: dark)').matches : await this.storage.get(this.COLOR_SCHEME_KEY);
    }
  }

  async isSystemTheme(): Promise<boolean> {
    return await this.storage.get(this.SYSTEM_THEME_KEY) == null ? true : await this.storage.get(this.SYSTEM_THEME_KEY);
  }

  async isDebugActive(): Promise<boolean> {
    return await this.storage.get(this.DEBUG_KEY) ?? false;
  }

  async init() {
    await this.storage.create();
  }

}
