import { Observable } from 'rxjs';
import { LogLevel } from '../log-level.enum';
import { LogEngine, LogMetadata, LogMessage } from './log-engine.abstract';

export class ConsoleLogEngine extends LogEngine {
    log(metadata: LogMetadata): Observable<LogMessage> {
        const message = this.createLogMessage(metadata);
        switch(metadata.level) {
            case LogLevel.TRACE:
                console.trace(message, metadata.additional);
                break;
            case LogLevel.DEBUG:
                console.debug(message, metadata.additional);
                break;
            case LogLevel.INFO:
                console.info(message, metadata.additional);
                break;
            case LogLevel.WARN:
                console.warn(message, metadata.additional);
                break;
            case LogLevel.ERROR:
            case LogLevel.FATAL:
                console.error(message, metadata.additional);
                break;
            case LogLevel.LOG:
            default:
                console.log(message, metadata.additional);
        }
        return new Observable((subscriber) => {
            subscriber.next(null);
            subscriber.complete();
        });
    }

    protected createLogMessage(metadata: LogMetadata): string {
        return `[${this.ngDatePipe.transform(new Date(), metadata.timestampFormat)}] - ${metadata.message}`;
    }
}
