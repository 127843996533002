export enum CurrentState {
    DISCONNECTED = -2,
    UNKNOWN = -1,
    INIT = 0,
    NOT_CALIBRATED = 1,
    CALIBRATED = 2,
    MOVE = 3,
    JOG = 4,
    MACHINE_ERROR = 5,
    BREAKED = 6,
    INIT_DISCOVER = 254, /* UI internal state if we are initializing joints  */
    COMMAND = 255, /* state machine busy keep previous state */
    UPDATING = 900, /* non è uno stato della macchina, ma se succede vuol dire che edo si sta aggiornando */
}

export type MachineState = {
    current_state: CurrentState;
    opcode: number;
};
