import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs'; 
import { filter, map} from 'rxjs/operators';
import { RosService } from './ros.service';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {

  isUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private updateToast: HTMLIonToastElement;

  constructor(
    private updates: SwUpdate,
    private toastCtrl: ToastController,
    private translateSrv: TranslateService,
    private rosSrv: RosService)
  {
    const updatesAvailable = this.updates.versionUpdates
    .pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );
    updatesAvailable.subscribe(() => {
      this.isUpdateAvailable.next(true);
    });
  }

  get isUpdateAvailableValue(): boolean {
    return this.isUpdateAvailable.getValue();
  }

  doAppUpdate() {
    this.updateToast?.dismiss();
    this.updates.activateUpdate().then(async () => {
      await this.rosSrv.disconnect();
      this.isUpdateAvailable.next(false);
      document.location.reload();
    });
  }

  async showAppUpdateToast() {
    this.updateToast = await this.toastCtrl.create({
      message: 'Un update è disponibile, vuoi effettuare l\'update?',
      buttons: [{
        text: this.translateSrv.instant('update'),
        handler: () => this.doAppUpdate()
      },
      {
        icon: 'close',
        role: 'cancel'
      }],
      keyboardClose: true,
      position: 'top'      
    });
    this.updateToast.present();
  }
}
