export const environment = {
    production: true,
    enableDebug: true,
    combo: {
        production: false,
        isCombo: false
    },
    firebase: {
        apiKey: "AIzaSyDf8V4FUMxDnPJsOoLHD2yI8B1QfOl3UMQ",
        authDomain: "edo-dev-327611.firebaseapp.com",
        projectId: "edo-dev-327611",
        storageBucket: "edo-dev-327611.appspot.com",
        messagingSenderId: "1031972512414",
        appId: "1:1031972512414:web:ccc17ba40fd98f34d6518f"
    },
    emulator: false,
    log: {
        type: []
    }
};
  