import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

export enum ScreenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly SCREEN_SIZE = [
    {name: 'xs', min: 0, max: 575},
    {name: 'sm', min: 576, max: 767},
    {name: 'md', min: 768, max: 991},
    {name: 'lg', min: 992, max: 1199},
    {name: 'xl', min: 1200, max: 9999}
  ];

  constructor(
    private platform: Platform
  ) { }

  get screenSize(): Array<{name: string; min: number; max: number}>{
    return [...this.SCREEN_SIZE];
  }


  // TODO: refactor --> width e size come oggetti con width nullable,
  // se nullable la funziona prende window.innerwidth
  isScreenEqualOrLower(width: number, size: string) {
    return this.SCREEN_SIZE.find(s => s.name === size).max >= width;
  }

  // TODO: refactor --> width e size come oggetti con width nullable,
  // se nullable la funziona prende window.innerwidth
  isScreenEqual(width: number, size: string) {
    const screenSize = this.SCREEN_SIZE.find(s => s.name === size);
    return screenSize.min <= width && screenSize.max >= width;
  }

  initTimezone(langCode: string): {timezone: string; locale: string} {
    const timezone = window.Intl && typeof window.Intl === 'object' ?
      Intl.DateTimeFormat().resolvedOptions().timeZone : new Date().getTimezoneOffset().toString();
    const locale = langCode;
    return {timezone, locale};
  }

  // code get from ngx-datatable
  // https://github.com/swimlane/ngx-datatable/blob/be41f9fe6438b772a3770c8f6350c1d7b2a48a83/
  // projects/swimlane/ngx-datatable/src/lib/utils/sort.ts#L32
  orderByComparator(a: any, b: any): number {
    if (a === null || typeof a === 'undefined') { a = 0; }
    if (b === null || typeof b === 'undefined') { b = 0; }
    if (a instanceof Date && b instanceof Date) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
    } else if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
      // Convert to string in case of a=0 or b=0
      a = String(a);
      b = String(b);
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) { return -1; }
      if (a.toLowerCase() > b.toLowerCase()) { return 1; }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      if (parseFloat(a) > parseFloat(b)) { return 1; }
    }
    // equal each other
    return 0;
  }

  // code edited from ngx-datatable
  // https://github.com/swimlane/ngx-datatable/blob/be41f9fe6438b772a3770c8f6350c1d7b2a48a83/
  // projects/swimlane/ngx-datatable/src/lib/utils/sort.ts#L8
  nextSortDir(current: SortDirection): SortDirection | undefined {
    if (!current) {
      return SortDirection.asc;
    } else if (current === SortDirection.asc) {
      return SortDirection.desc;
    } else if (current === SortDirection.desc) {
      return undefined;
    }
  }

  public isBrowser() {
    return this.platform.is('pwa') || this.platform.is('mobileweb') || document.URL.startsWith('http');
  }

  public isLocalhost() {
    return document.URL.startsWith('http://localhost');
  }

  public isDesktop() {
    return this.platform.is('electron') && this.platform.is('desktop');
  }

  public isElectron() {
    return this.platform.is('electron');
  }

  public isMobile() {
    return !this.isDesktop() && !this.isBrowser();
  }

  public isAndroid() {
    return this.platform.is('android');
  }

  public isIos() {
    return this.platform.is('ios');
  }

  /**
   *
   * @param dir  all dir that you want to merge
   * @returns a string that is the result of all dir merged
   */
  public mergeDir(...dir: string[]): string{
    if (dir.length === 0) { return ''; }
    if (dir.length === 1) { return dir[0]; }
    let res: string = dir[0];
    for (let i = 0; i < dir.length - 1; i++ ) {
      if (dir[i].endsWith('\\') || dir[i].endsWith('/')){
        res += dir[i + 1];
      }else if (dir[i].includes('\\')) {
        res += '\\' + dir[i + 1];
      }else if (dir[i].includes('/')) {
        res += '/' + dir[i + 1];
      }
    }
    return res;
  }

  degreeToRad(degree: number) {
    if (!degree) { return 0; }
    return degree * Math.PI / 180;
  }

  radiantToDeg(radiant: number) {
    if (!radiant) { return 0; }
    return radiant * 180 / Math.PI;
  }

  millimetersToRad(mm: number): number {
    mm = mm <= 0 ? -11 : mm >= 80 ? 80 : mm;
    let sfSjnt = (mm - 1) / 80;
    if (sfSjnt > 1.0) {
      sfSjnt = 1.0;
    }
    return Math.asin(sfSjnt); /* da 0 a 90 gradi ma in rad */
  }
  /**
   *
   * @param array the array you want group
   * @param props the props or keys you want use to group
   * @returns an array with props keys and quantity key
   */
  groupBy(array: Array<any>, props: string[]) {
    if (!array) { return null; }
    const helper = {};
    let key = '';
    const result = array.reduce((previous, current) => {
      key = '';
      props.forEach(prop => key += `${current[prop]}-`);
      key = key.slice(0, -1);
      if (!helper[key]) {
        helper[key] = Object.assign({}, {...current, quantity: 1}); // create a copy of o
        const v = {};
        props.forEach(prop => {
          v[prop] = current[prop];
        });
        // eslint-disable-next-line @typescript-eslint/dot-notation
        v['quantity'] = 1;
        previous.push(v);
      } else {
        helper[key].quantity += 1;
        const keys = Object.keys(helper);
        const idx = keys.findIndex(help => help === key);
        previous[idx].quantity = helper[key].quantity;
      }
      return previous;
    }, []);
    return result;
  }
}
