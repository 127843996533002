import { Injectable } from '@angular/core';
import { EdoStorageService } from './edo-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {

  private prefersColor: MediaQueryList;

  constructor(
    private edoStorage: EdoStorageService
  ) {
    this.init();
  }

  private init() {
    this.prefersColor = window.matchMedia('(prefers-color-scheme: dark)');
    this.prefersColor.addEventListener(
      'change',
      async mediaQuery => {
        if (await this.edoStorage.isSystemTheme()) {
          this.setColorSchemeSupport(mediaQuery.matches);
        }
      }
    );
  }

  async setColorScheme() {
    const isDark = await this.edoStorage.isDarkMode();
    this.setColorSchemeSupport(isDark);
  }

  private setColorSchemeSupport(isDark: boolean) {
    if (isDark && !document.body.classList.contains('dark')) {
      document.body.classList.add('dark');
    } else if (!isDark && document.body.classList.contains('dark')) {
      document.body.classList.remove('dark');
    }
  }
}
