import { Timestamp, FirestoreDataConverter } from '@angular/fire/firestore';

export type UserInfo = {
  email: string;
  uid: string;
  name: string;
};
export type Lesson = {
  lessonId?: string;
  team: string;
  name: string;
  startDate: Timestamp;
  endDate: Timestamp;
  code: string;
  isLockScreen: boolean;
  forceRaspberry?: boolean;
  useEdoCloud?: boolean;
  isEdoCloudActive?: boolean;
  userInfo: UserInfo;
};

//TODO: manage better? study better firebase 9 implementation.
export const lessonConverter: FirestoreDataConverter<Lesson> = {
  toFirestore: (lesson: Lesson) => ({...lesson, userInfo: {...lesson.userInfo}}),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options) as Lesson;
    return {
      ...data,
      userInfo: {...data.userInfo}
    };
  }
};
