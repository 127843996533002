import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { AlertInput } from '@ionic/core/dist/types/interface';

export type Lang = {
  flagPath: string;
  code: string;
  text: string;
};
const BASE_PATH = 'assets/images/flags';

export const AVAILABLE_LANGUAGES: Array<Lang> = [
  { flagPath: `${BASE_PATH}/it.svg`, code: 'it', text: 'Italiano'},
  { flagPath: `${BASE_PATH}/en.svg`, code: 'en', text: 'English'},
  { flagPath: `${BASE_PATH}/zh.svg`, code: 'zh', text: '中文'},
  { flagPath: `${BASE_PATH}/ru.svg`, code: 'ru', text: 'русский'},
  { flagPath: `${BASE_PATH}/es.svg`, code: 'es', text: 'Español'},
];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) { }

  get lang(): Lang {
    const actualLang: string = this.translate.currentLang || this.translate.defaultLang;
    return AVAILABLE_LANGUAGES.find(value => actualLang === value.code);
  }

  async chooseLanguage() {
    const actualLang: string = this.translate.currentLang || this.translate.defaultLang;
    const inputs: AlertInput[] = this.translate.langs.map(value => ({
        type: 'radio',
        name: value,
        value,
        label: AVAILABLE_LANGUAGES.find(lang => lang.code === value).text,
        checked: actualLang === value
      }));
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('language'),
      inputs,
      buttons: [{
        text: this.translate.instant('cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('ok'),
        handler: (value) => {
          this.translate.use(value);
        }
      }]
    });

    alert.present();
  }

}
