/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { Ros, Service, ServiceRequest, Topic } from 'roslib';
import { Observable, Subscriber, Subject, of, shareReplay, takeUntil } from 'rxjs';
import { MachineState } from '../machine-state/machine-state.model';
import { EdoVersionService } from './edo-version.service';
import { LicenseService } from './license.service';
import { MachineStateService } from '../machine-state/machine-state.service';
import { NodeSwVersion, UPDATE_STATE } from '../models';
import { Router } from '@angular/router';
import { LoginComboService } from '../login/login-combo/login-combo.service';
import { environment } from '../../environments/environment';
import { UtilityService } from './utility.service';


export enum ROS_CONNECTION {
  CONNECTION = 'connection',
  CLOSE = 'close',
  ERROR = 'error',
  TIMEOUT = 'timeout'
}

type NodeSwVersionArray = {
  nodes: NodeSwVersion[];
};

type SwVersionResponse = {
  version: NodeSwVersionArray;
  tool_id: number;
};

export type CartesianPos = {
  a: number;
  e: number;
  r: number;
  x: number;
  y: number;
  z: number;
  config_flags: string;
};

export const LICENSE_NOT_VALID = 'license_not_valid';

export type AppState = {
  position: number;
};

export type AppStateArray = {
  joints_mask: number;
  joints: AppState[];
};

export enum LOCK_GRIPPER {
  LOCK = 1,
  UNLOCK = 0
}

export type PenState = {
  width: number;
  color: string;
  active: boolean;
};

export enum ConnectionStatus {
  connected = 'connected',
  onDisconnect = 'onDisconnect',
  disconnected = 'disconnected',
}

export const wssPort = '9091';
export const wsPort = '9090';

@Injectable({
  providedIn: 'root'
})
export class RosService implements OnDestroy{

  readonly machineStateChangeEvent = new Subject<MachineState>();
  readonly logoutSubscription = new Subject<boolean>();

  edoState: Observable<string>;
  showPopupForHttpsCertsSubj = new Subject<void>();
  jointState$: Observable<AppStateArray>;

  private ros: Ros;
  private edoStateObserver: Subscriber<string>;
  private connectTimeoutTimer: number;
  private serviceSwVersion: Service;
  private edoInitialInfo: SwVersionResponse;
  private topicMachineState: Topic;
  private topicJntState: Topic;
  private topicCartesianPose: Topic;
  private topicLockGripper: Topic;
  private topicPenState: Topic;
  private connectStatus: ConnectionStatus = ConnectionStatus.disconnected;
  private isCombo: boolean = environment.combo.isCombo;

  private readonly SERVICE_NAME = '/machine_bridge_sw_version_srv';
  private readonly SERVICE_TYPE = 'edo_core_msgs/NodeSwVersionArray';

  private readonly MACHINE_STATE_NAME = '/machine_state';
  private readonly MACHINE_STATE_TYPE = 'edo_core_msgs/MachineState';

  private readonly JOINT_STATE_NAME = '/app_jnt_state';
  private readonly JOINT_STATE_TYPE =  'edo_core_msgs/AppStateArray';

  private readonly CARTESIAN_STATE_NAME = '/cartesian_pose';
  private readonly CARTESIAN_STATE_TYPE = 'edo_core_msgs/CartesianPose';

  private readonly LOCK_GRIPPER_NAME = '/objects_state';
  private readonly LOCK_GRIPPER_TYPE = 'std_msgs/Int8';

  private readonly PEN_STATE_NAME = '/pen_state';
  private readonly PEN_STATE_TYPE = 'edo_core_msgs/Pen';

  private firstTimeConnection = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private edoVersionSrv: EdoVersionService,
    private licenseSrv: LicenseService,
    private machineStateSrv: MachineStateService,
    private router: Router,
    private loginComboSrv: LoginComboService,
    private utility: UtilityService
  ) {
    this.edoState = new Observable(observer => this.edoStateObserver = observer);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // TODO like above
  get cartesianPos(): Topic {
    return this.topicCartesianPose;
  }

  get penState(): Topic {
    return this.topicPenState;
  }

  get connectionStatus(): ConnectionStatus {
    return this.connectStatus;
  }

  isConnect(): boolean {
    return !!this.ros;
  }

  connect(url: string, auth?: string) {
    url = this.getRightUrl(url, auth);
    this.ros = new Ros({
      url
    });

    this.ros.on(ROS_CONNECTION.CONNECTION, async () => {
      this.firstTimeConnection = false;
      this.connectStatus = ConnectionStatus.connected;
      clearTimeout(this.connectTimeoutTimer);
      this.createStartInfoService();
      try {
        this.edoInitialInfo = await this.getInitialInfo();
        // TODO: this is totally wrong, why checksoftware version get the update state...
        const updateState = this.edoVersionSrv.checkSoftwareVersion([...this.edoInitialInfo.version.nodes]);
        if (updateState !== UPDATE_STATE.TO_NOT_UPDATE) {
          this.edoStateObserver.next(updateState);
          this.disconnect();
          return;
        }

        const macAdd = this.edoInitialInfo.version.nodes[2].version;
        const edoType = this.getEdoType();
        if (edoType === 'cube') {
          const licString = this.edoInitialInfo.version.nodes[3].version;
          const activeLicense = this.licenseSrv.getLicenseType(macAdd, licString);
          if (this.isCombo || this.licenseSrv.isValidLicense(activeLicense)){
            this.initCommands();
            this.edoStateObserver.next(ROS_CONNECTION.CONNECTION);
          } else {
            this.edoStateObserver.next(LICENSE_NOT_VALID);
          }
        } else {
          this.initCommands();
          this.edoStateObserver.next(ROS_CONNECTION.CONNECTION);
        }
      } catch (e) {
        // molto probabilmente in questo caso il machine state non risponde
        this.edoStateObserver.next(ROS_CONNECTION.ERROR);
      }
      this.lockGripper(LOCK_GRIPPER.UNLOCK);

    });

    this.ros.on(ROS_CONNECTION.CLOSE, (event) => {
      console.warn('close', event);
      if (this.connectStatus === ConnectionStatus.connected) {
        this.disconnect(true);
      }
    });

    this.ros.on(ROS_CONNECTION.ERROR, (error) => {
      console.error('error', error);
      if (this.firstTimeConnection) {
        this.firstTimeConnection = false;
        this.showPopupForHttpsCertsSubj.next();
      }
      if (this.connectStatus === ConnectionStatus.connected) {
        this.disconnect();
      }
    });

    this.connectTimeoutTimer = window.setTimeout(() => {
      // this.machineState.current_state = CurrentState.UNKNOWN; // force a connectedChangeEvent
      // this.machineState.opcode = 0;
      this.edoStateObserver?.next(ROS_CONNECTION.TIMEOUT);
    }, 5000);
  }

  async disconnect(ros?: boolean) {
    this.connectStatus = ConnectionStatus.onDisconnect;
    if (!ros && this.ros) {
      this.ros.close();
      this.ros = null;
    }
    if (this.isCombo) {
      await this.loginComboSrv.decrementAppVirtual();
    }

    if (this.topicJntState) {
      this.topicJntState.unsubscribe();
      this.topicJntState = null;
    }

    if (this.topicMachineState) {
      this.topicMachineState.unsubscribe();
      this.topicMachineState = null;
    }

    if (this.topicCartesianPose) {
      this.topicCartesianPose.unsubscribe();
      this.topicCartesianPose = null;
    }

    if (this.topicLockGripper) {
      this.topicLockGripper.unsubscribe();
      this.topicLockGripper = null;
    }

    if (this.topicPenState) {
      this.topicPenState.unsubscribe();
      this.topicPenState = null;
    }

    this.machineStateSrv.clear();

    this.router.navigate(['/login'], {replaceUrl: true});

    this.logoutSubscription.next(true);
    this.connectStatus = ConnectionStatus.disconnected;
  }

  lockGripper(data: number) {
    this.topicLockGripper?.publish({
      data
    });
  }

  private createStartInfoService() {
    this.serviceSwVersion = new Service({
      ros: this.ros,
      name: this.SERVICE_NAME,
      serviceType: this.SERVICE_TYPE
    });
  }

  private async getInitialInfo(): Promise<SwVersionResponse> {
    return new Promise<SwVersionResponse>((resolve, reject) => {
      this.serviceSwVersion.callService(new ServiceRequest({}), (result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      });
    });
  }

  private initCommands() {
    // Machine state, in quest'app mi serve per controllare che e.DO sia completamente configurato e pronto ad essere usato
    this.topicMachineState = new Topic({
      ros: this.ros,
      name: this.MACHINE_STATE_NAME,
      messageType: this.MACHINE_STATE_TYPE,
      throttle_rate: 200 // 0.1 seconds
    });
    this.topicMachineState.subscribe(message => {
      const newState: MachineState = message as MachineState;
      if (this.machineStateSrv.toUpdate(newState)) {
        this.machineStateSrv.machineState = {...newState};
        this.machineStateChangeEvent.next({...newState});
      }
    });

    // Topic che ritorna il valore dei giunti
    this.topicJntState = new Topic({
      ros: this.ros,
      name: this.JOINT_STATE_NAME,
      messageType: this.JOINT_STATE_TYPE,
      throttle_rate: 200 // 0.1 seconds
    });

    this.jointState$ = new Observable<AppStateArray>(observer => {
      this.topicJntState.subscribe(message => {
        observer.next(message as AppStateArray);
      });
    }).pipe(
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    // Topic che ritorna le posizioni cartesiane dei giunti
    this.topicCartesianPose = new Topic({
      ros: this.ros,
      name: this.CARTESIAN_STATE_NAME,
      messageType: this.CARTESIAN_STATE_TYPE,
      throttle_rate: 200 // 0.1 seconds
    });

    this.topicLockGripper = new Topic({
      ros: this.ros,
      name: this.LOCK_GRIPPER_NAME,
      messageType: this.LOCK_GRIPPER_TYPE
    });

    this.topicPenState = new Topic({
      ros: this.ros,
      name: this.PEN_STATE_NAME,
      messageType: this.PEN_STATE_TYPE
    });
  }

  private getRightUrl(url: string, auth?: string): string {
    if (this.isCombo) {
      url = auth ? `${url}?apikey=${auth}` : url;
      url = url.includes('web') || url.includes('edo.cloud') ? `wss://${url}` : `ws://${url}`;
      console.log('URL', url);
    } else {
      const protocol = this.utility.isBrowser() && !this.utility.isLocalhost() ? 'wss://' : 'ws://';
      const port = this.utility.isBrowser() && !this.utility.isLocalhost() ? wssPort : wsPort;
      url = `${protocol}${url}:${port}`;
    }
    return url;
  }

  private getEdoType(): string {
    return this.edoInitialInfo.version.nodes[1].version.split('_')[0];
  }
}
