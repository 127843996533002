import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

export type EdoCloud = {
  inUse: boolean;
  appUi: number;
  appVirtual: number;
  team: string;
  lessonId: string;
  lessonName: string;
};

export const KUBERNETES_COLLECTION = 'kubernetes';

export enum EdoKubernetesInfrStatus {
  toCreate = 'toCreate',
  created = 'created',
  error = 'error'
}

export type EdoKubernetes = {
  name: string;
  path: string;
  infrStatus: EdoKubernetesInfrStatus;
  creationDate: Timestamp;
} & EdoCloud;

//TODO: manage better? study better firebase 9 implementation.
export const edoCloudConverter: FirestoreDataConverter<EdoKubernetes> = {
  toFirestore: (edo: EdoKubernetes) => ({...edo}),
  fromFirestore: (snapshot, options) => {
    const edo = snapshot.data(options) as EdoKubernetes;
    return {...edo};
  }
};
