import { Injectable, Optional, Inject, OnDestroy } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { LogConfig, logConfigConverter, LogType, LOG_CONFIG } from './config/log-config';
import { LogMessage, LogMetadata } from './engine/log-engine.abstract';
import { LogLevel } from './log-level.enum';
import { DatePipe } from '@angular/common';
import { Observable, Subject, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LoginComboService } from '../login/login-combo/login-combo.service';
import { ConsoleLogEngine } from './engine/console-log.engine';
import { FirebaseLogEngine } from './engine/firebase-log.engine';
import { environment } from '../../environments/environment';

type FirebaseLogInfo = {
  id: string,
  logName: string,
  messages: LogMessage[]
};

@Injectable({
  providedIn: 'root'
})
export class LogService implements OnDestroy {
  private readonly docPath = 'log/virtual';

  private id: string;
  private config: LogConfig;
  private logMessageQueue: LogMessage[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private oldLevel: LogLevel;
  private isCombo = environment.combo.isCombo;

  constructor(
    @Optional() @Inject(LOG_CONFIG) config: LogConfig,
    private functions: Functions,
    private datePipe: DatePipe,
    private loginSrv: LoginComboService,
    private firestore: Firestore
  ) {
    // TODO: manage log configuration better.
    this.id = this.createId();
    this.config = config ?? LogConfig.getDefaultConfig();
    this.subscribeLogConfiguration();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$ = null;
  }

  get level(): LogLevel {
    return this.config.level
  }

  get messageQueue(): LogMessage[] {
    return this.logMessageQueue;
  }

  trace(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.TRACE, message, additional);
  }

  debug(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.DEBUG, message, additional);
  }
  
  info(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.INFO, message, additional);
  }

  log(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.LOG, message, additional);
  }

  warn(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.WARN, message, additional);
  }
  
  error(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.ERROR, message, additional);
  }
  
  fatal(message: string, ...additional: any[]): void {
    this.writeLog(LogLevel.FATAL, message, additional);
  }

  sendLogFirebaseEngine(messages?: LogMessage[]): Observable<string>  {
    if (!this.isCombo) {
      return of(null);
    }
    messages = messages ?? this.messageQueue;
    const fnc = httpsCallableData<FirebaseLogInfo, string>(this.functions, 'writeLog');
    const logInfo: FirebaseLogInfo = {
      id: this.id,
      logName: 'edo-virtual-log',
      messages
    }
    return fnc(logInfo);
  }

  disable() {
    if (this.config.level === LogLevel.OFF) {
      return;
    }
    this.oldLevel = this.config.level;
    this.config.level = LogLevel.OFF;
  }

  enable() {
    if (this.config.level !== LogLevel.OFF) {
      return;
    }
    this.config.level = this.oldLevel ?? this.config.level;
    this.oldLevel = this.config.level;
  }

  private subscribeLogConfiguration() {
    if (!this.isCombo) {
      return;
    }
    const ref = doc(this.firestore, this.docPath).withConverter(logConfigConverter);
    docData<LogConfig>(ref)
      .pipe(takeUntil(this.destroy$))
      .subscribe((logConfig) => {
        if (logConfig) {
          this.config = logConfig;
        }
      });
  }

  private writeLog(level: LogLevel, message?: string, additional: any[] = []): void {
    if (!this.isCombo && !this.shouldWriteThisLog(level)) {
      return;
    }
    const metadata: LogMetadata = {
      level,
      message,
      additional,
      timestampFormat: this.config.timestampFormat,
      lesson: {
        lessonId: this.loginSrv.lesson.lessonId,
        team: this.loginSrv.lesson.team,
      }
    };
    for(const type of this.config.type) {
      const engine = this.getInstance(type);
      engine.datePipe = this.datePipe
      engine.log(metadata).subscribe((logMessage) => {
        if (!logMessage) {
          return;
        }
        this.logMessageQueue.push(logMessage);
        if (this.isMessageToSend()) {
          const messageQueue = this.logMessageQueue;
          this.logMessageQueue = [];
          try {
            this.sendLogFirebaseEngine(messageQueue)
              .pipe(take(1))
              .subscribe();
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
  }

  private isMessageToSend(): boolean {
    return !this.config.sendLogImmediatly && this.getByteDimension(this.logMessageQueue.map(message => message.text)) >= this.config.maxSizeLogByte;
  }

  private getByteDimension(value: string | string[]) {
    let bytes: number = 0;
    if (Array.isArray(value)) {
      for (const v of value) {
        bytes += new TextEncoder().encode(v).length;
      }
      return bytes;
    }

    return new TextEncoder().encode(value).length;
  }

  private shouldWriteThisLog(level: LogLevel) {
    return this.config.level !== LogLevel.OFF && level >= this.config.level;
  }

  private getInstance(type: number) {
    switch(type) {
      case LogType.console:
        return new ConsoleLogEngine();
      case LogType.edoFire:
        return new FirebaseLogEngine();
    }
  }

  private createId(): string {
    const team = this.loginSrv.lesson.team;
    const lessonCode = this.loginSrv.lesson.code;
    const now = Date.now();
    return 'virtual'+team+lessonCode+now;
  }

}
