import { Observable } from 'rxjs';
import { LogLevel } from '../log-level.enum';
import { DatePipe } from '@angular/common';
import { Lesson } from '../../models/lesson';

export interface LogMetadata {
    message: any | (() => any);
    additional: any;
    level: LogLevel;
    timestampFormat: string;
    lesson: Partial<Lesson>;
}

export type LogMessage = {
    timestamp: Date;
    text: string;
    severity: string,
    source: "ui"|"virtual"|"dashboard",
    lesson: Partial<Lesson>
};

export abstract class LogEngine {
    protected ngDatePipe: DatePipe;

    set datePipe(datePipe: DatePipe) {
        this.ngDatePipe = datePipe;
    }

    abstract log(metadata: LogMetadata): Observable<LogMessage>;

    protected abstract createLogMessage(metadata: LogMetadata): string | LogMessage;
}
