import { InjectionToken } from '@angular/core';
import { FirestoreDataConverter } from 'firebase/firestore';
import { LogLevel } from '../log-level.enum';

export enum LogType {
    console,
    edoFire
}

export const LOG_CONFIG: InjectionToken<LogConfig> = new InjectionToken<LogConfig>("LOG_CONFIG");

export class LogConfig {
    private static readonly defaultTimestamp = 'dd/MM/yyyy HH:mm:ss:SSS';

    level: LogLevel = LogLevel.DEBUG;
    type: LogType[] = [LogType.console];
    timestampFormat: string = LogConfig.defaultTimestamp;
    sendLogImmediatly: boolean = true;
    maxSizeLogByte: number = 1000;

    static getDefaultConfig(): LogConfig {
        return new LogConfig();
    }
}

//TODO: manage better? study better firebase 9 implementation.
export const logConfigConverter: FirestoreDataConverter<LogConfig> = {
    toFirestore: (config: LogConfig) => {
      return {
        level: config.level,
        type: config.type,
        timestampFormat: config.timestampFormat,
        sendLogImmediatly: config.sendLogImmediatly,
        maxSizeLogByte: config.maxSizeLogByte
      };
    },
    fromFirestore: (snapshot, options) => {
      const config = snapshot.data(options);
      return {
        level: config.level,
        type: config.type,
        timestampFormat: config.timestampFormat,
        sendLogImmediatly: config.sendLogImmediatly,
        maxSizeLogByte: config.maxSizeLogByte
      }
    }
  }