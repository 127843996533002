import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NgxElectronModule } from 'ngx-electron';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicStorageModule } from '@ionic/storage-angular';
import { File } from '@awesome-cordova-plugins/file/ngx';

import localeRu from '@angular/common/locales/ru';
import localeZh from '@angular/common/locales/zh';
import localeIt from '@angular/common/locales/it';
import { DatePipe, registerLocaleData } from '@angular/common';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';

import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore, connectFirestoreEmulator, initializeFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';

import { environment } from '../environments/environment';
import { LogConfig, LOG_CONFIG } from './logging/config/log-config';
import { LogService } from './logging/log.service';
import { PwaUpdateService } from './services/pwa-update.service';
import { LockScreenComponent } from './components/lock-screen/lock-screen.component';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeRu);
registerLocaleData(localeZh);
registerLocaleData(localeIt);

export const createTranslateLoader =
    (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');


const firebaseLogConfiguration: LogConfig = {
  ...LogConfig.getDefaultConfig(),
  sendLogImmediatly: false,
  maxSizeLogByte: 5000,
  type: environment.log.type,
};

// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
      // I will deactivate the others to avoid overlaps
      pinch: { enable: false },
      rotate: { enable: false }
  };
}

@NgModule({
    declarations: [
        AppComponent,
        LockScreenComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgxElectronModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }),
        IonicStorageModule.forRoot(),
        HammerModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            // TODO: think about add enablePersistence for offline use.
            initializeFirestore(getApp(), {
                ignoreUndefinedProperties: true
            });
            const firestore = getFirestore();
            if (environment.emulator) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.emulator) {
                connectAuthEmulator(auth, 'http://localhost:9099');
            }
            return auth;
        }),
        provideFunctions(() => {
            //TODO: work better with regions!
            const fnc = getFunctions();
            fnc.region = 'europe-west1';
            if (environment.emulator) {
                connectFunctionsEmulator(fnc, 'localhost', 5001);
            }
            return fnc;
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production && !environment.combo.isCombo,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })

    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        File,
        Chooser,
        DatePipe,
        LogService,
        PwaUpdateService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        { provide: LOG_CONFIG, useValue: firebaseLogConfiguration }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
