export type NodeSwVersion = {
    id: number;
    version: string
};

export enum UPDATE_STATE {
    TO_NOT_UPDATE = 'to_not_update',
    TO_UPDATE = 'to_update',
    CHEK_FOR_UPDATE = 'check_for_update',
    TO_APP_UPDATE = 'to_app_update'
}
