import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RosService } from '../services/ros.service';

@Injectable({
  providedIn: 'root'
})
export class RosConnectedGuard implements CanActivate {

  constructor(
    private ros: RosService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.ros.isConnect()) {
        return true;
      }
      this.router.navigate(['login']);
      return false;
  }

}
