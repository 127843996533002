import { Injectable } from '@angular/core';
import { MachineState, CurrentState } from './machine-state.model';

@Injectable({
  providedIn: 'root'
})
export class MachineStateService {

  machineState: MachineState;

  constructor() { }

  toUpdate(state: MachineState) {
    return !this.machineState || (
      (
        this.machineState.current_state !== state.current_state ||
        this.machineState.opcode !== state.opcode
      ) && state.current_state < CurrentState.COMMAND
    );
  }

  clear() {
    this.machineState = null;
  }
}
