export type BrushColor = {
    r: number;
    g: number;
    b: number;
};

export class VirtualBrush {
    static readonly colorStringToRgb: Array<{name: string, value: BrushColor}> = [
        {
          name: 'black',
          value: {r: 0, g: 0, b: 0}
        }, {
          name: 'silver',
          value: {r: 192, g: 192, b: 192}
        }, {
          name: 'gray',
          value: {r: 128, g: 128, b: 128}
        }, {
          name: 'white',
          value: {r: 255, g: 255, b: 255}
        }, {
          name: 'maroon',
          value: {r: 128, g: 0, b: 0}
        }, {
          name: 'red',
          value: {r: 255, g: 0, b: 0}
        }, {
          name: 'purple',
          value: {r: 128, g: 0, b: 128}
        }, {
          name: 'fuchsia',
          value: {r: 255, g: 0, b: 255}
        }, {
          name: 'green',
          value: {r: 0, g: 128, b: 0}
        }, {
          name: 'lime',
          value: {r: 0, g: 255, b: 0}
        }, {
          name: 'olive',
          value: {r: 128, g: 128, b: 0}
        }, {
          name: 'yellow',
          value: {r: 255, g: 255, b: 0}
        }, {
          name: 'navy',
          value: {r: 0, g: 0, b: 128}
        }, {
          name: 'blue',
          value: {r: 0, g: 0, b: 255}
        }, {
          name: 'teal',
          value: {r: 0, g: 128, b: 128}
        }, {
          name: 'aqua',
          value: {r: 0, g: 255, b: 255}
        }
      ];

    constructor(
        public color: BrushColor = { r: 0, g: 0, b: 0},
        public weight: number = 1
    ) {
      this.weight = weight <= 0 ? 1 : weight;
    }

    static cssStringToRgb(color: string): BrushColor {
        return VirtualBrush.colorStringToRgb.find(v => v.name === color).value;
    }

    get colorString() {
        return `rgb(${this.color.r}, ${this.color.g}, ${this.color.b})`;
    }

    get colorStringRGBA() {
        return `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, 1)`;
    }

}
