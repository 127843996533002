import { Injectable } from '@angular/core';
import { Firestore, CollectionReference, Timestamp, where, 
  collection, getDocs, query, runTransaction } from '@angular/fire/firestore';
import { collectionData } from 'rxfire/firestore';
import { Auth, UserCredential, signInAnonymously, signOut } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Lesson, lessonConverter } from 'src/app/models/lesson';
import { Observable } from 'rxjs';
import { RaspberryDoc, getErrorMessage, raspberryConverter } from 'src/app/models/raspberry';
import { environment } from '../../../environments/environment';
import { EdoKubernetes, edoCloudConverter } from 'src/app/models/EdoCloud';


interface SmazzaRequest {
  type: "virtual",
  team: string,
  lessonId: string
}

export interface SmazzaResponse {
  data: {
      ip?: number,
      hostname?: string
      token?: string
  },
  error: {
      code?: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class LoginComboService {
  raspberryIp: number;

  private userCredential: UserCredential;
  private myLesson: Lesson;
  private raspberryHostname: string;
  private isCombo = environment.combo.isCombo;
  private raspberryCollectionRef: CollectionReference<RaspberryDoc>;
  private edoCloudCollectionRef: CollectionReference<EdoKubernetes>;
  private myCollectionRef: CollectionReference;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private functions: Functions
  ) {
    this.raspberryCollectionRef = collection(this.firestore, 'raspberry')
      .withConverter(raspberryConverter);
    this.edoCloudCollectionRef = collection(this.firestore, 'kubernetes')
      .withConverter(edoCloudConverter);
  }

  get credential(): UserCredential {
    return {...this.userCredential};
  }

  get lesson(): Lesson {
    return {...this.myLesson};
  }

  async login(team: string, code: string): Promise<SmazzaResponse> {
    if (!this.isCombo) {
      return;
    }
    this.userCredential = await signInAnonymously(this.auth);
    this.myLesson = await this.isValidTimeAndTeamAndCode(team, code);
    const res = await this.getFreeRaspberry(team, this.myLesson.lessonId);
    if (res.error){
      const message = getErrorMessage(res.error.code);
      throw new Error(message);
    }
    this.raspberryIp = res.data.ip;
    this.raspberryHostname = res.data.hostname;
    this.myCollectionRef = this.myLesson.useEdoCloud ? this.edoCloudCollectionRef : this.raspberryCollectionRef;
    return res;
  }

  async logout() {
    if (!this.isCombo) {
      return;
    }
    try {
      await this.deleteMe();
      await signOut(this.auth);
    } catch (error) {
      console.error(error);
    }
  }

  async decrementAppVirtual() {
    if (!this.isCombo) {
      return;
    }
    const lessonCondition = where('lessonId', '==', this.myLesson.lessonId);
    const teamCondition = where('team', '==', this.myLesson.team);
    const queryRasp = query(this.myCollectionRef, lessonCondition, teamCondition);
    const edos = await getDocs(queryRasp);

    if (edos.size > 1 || edos.empty) {
      console.error(`Attenzione! impossibile decrementare il valore, ip: ${this.raspberryIp}, trovato ${edos.size} volte`);
      return;
    }

    const ref = edos.docs[0].ref;
    await runTransaction(this.firestore, async transaction => {
      const doc = await transaction.get(ref);
      const appVirtual = doc.data().appVirtual > 0 ? doc.data().appVirtual - 1 : 0;
      if (appVirtual === 0 && doc.data().appUi === 0) {
        transaction.update(ref, {appVirtual, team: '', lessonId: '', lessonName: ''});
      } else {
        transaction.update(ref, {appVirtual});
      }
    });
  }

  getMyRaspberryObs(hostname: string): Observable<RaspberryDoc[]> {
    if (!this.isCombo) {
      return;
    }
    const queryConstr = where('hostname', '==', hostname);
    const queryRasp = query(this.raspberryCollectionRef, queryConstr);
    return collectionData(queryRasp);
  }

  getMyEdoCloud(name: string): Observable<EdoKubernetes[]> {
    if (!this.isCombo) {
      return;
    }
    const queryConstr = where('name', '==', name);
    const queryEdo = query(this.edoCloudCollectionRef, queryConstr);
    return collectionData(queryEdo);
  }

  private async deleteMe(): Promise<void> {
    if (!this.isCombo) {
      throw new Error('This is not combo environment');
    }
    const callable = httpsCallable<string, void>(this.functions, 'removeAnonymousUserAfterLogout');
    const myUid = this.credential.user.uid;
    await callable(myUid);
  }

  private async isValidTimeAndTeamAndCode(team: string, code: string): Promise<Lesson> {
    const now = Timestamp.fromDate(new Date());
    const calendarCollRef = collection(this.firestore, 'calendar').withConverter(lessonConverter);
    const lessonQuery =  query(calendarCollRef,
      where('code', '==', code),
      where('teams', 'array-contains', team),
      where('startDate', '<=', now)
    );
    const snapshot = await getDocs(lessonQuery);
    if (snapshot.empty){
      throw new Error('combo.login.error.no-lesson-found');
    } else if (snapshot.docs.length > 1) {
      throw new Error('combo.login.error.too-many-lessons');
    }
    const documentSnapshot = snapshot.docs.filter(v =>  v.data().endDate.seconds >= now.seconds);
    return {
      ...documentSnapshot[0].data(),
      lessonId: documentSnapshot[0].id,
      team
    };
  }

  private async getFreeRaspberry(team: string, lessonId: string): Promise<SmazzaResponse> {
    const req: SmazzaRequest = {
      team, 
      lessonId,
      type: 'virtual'
    };
    const callable = httpsCallable<SmazzaRequest, SmazzaResponse>(this.functions, 'smazzaEdoVirtual');
    return (await callable(req)).data;
  }
}
