import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EdoStorageService } from './services/edo-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AVAILABLE_LANGUAGES } from './services/language.service';
import { ColorSchemeService } from './services/color-scheme.service';
import { PwaUpdateService } from './services/pwa-update.service';
import { environment } from '../environments/environment';
import { PhysicsLoader } from '@enable3d/ammo-physics';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private edoStorage: EdoStorageService,
    private translate: TranslateService,
    private colorScheme: ColorSchemeService,
    private updateSrv: PwaUpdateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await this.edoStorage.init();
      await this.initLanguages();
      await this.initSystemTheme();

      if (!environment.enableDebug) {
        this.edoStorage.debugMode = false;
      }
      this.colorScheme.setColorScheme();
    });

    if (!environment.combo.isCombo) {
      this.updateSrv.isUpdateAvailable
      .subscribe(isAvailable => {
        if (isAvailable)
          this.updateSrv.showAppUpdateToast();
      });
    }

    PhysicsLoader('assets/js', () => {});
  }

  private async initLanguages() {
    this.translate.addLangs(
      AVAILABLE_LANGUAGES.map(value => value.code)
    );
    try {
      const code: string = (await this.edoStorage.getLang())?.code ?? 'en';
      this.translate.use(code);
    } catch (e) {
      console.error('error in app.cmp.ts', e);
      this.translate.use('en');
    }
  }

  private async initSystemTheme() {
    try {
      if (await this.edoStorage.isSystemTheme() == null) {
        this.edoStorage.systemTheme = true;
      }
    } catch(e) {
      console.error('error in loading system theme', e);
      this.edoStorage.systemTheme = true;
    }
  }
}
