import { Observable } from 'rxjs';
import { LogMetadata, LogEngine, LogMessage } from './log-engine.abstract';

export class FirebaseLogEngine extends LogEngine {

    log(metadata: LogMetadata): Observable<LogMessage> {
        const logMessage = this.createLogMessage(metadata);
        return new Observable(subscriber => {
            subscriber.next(logMessage);
        });
    }

    protected createLogMessage(metadata: LogMetadata): LogMessage {
        return {
            timestamp: new Date(),
            text: metadata.message,
            severity: metadata.level,
            source: 'virtual',
            lesson: metadata.lesson
        };
    }
}