import { FirestoreDataConverter } from 'firebase/firestore';
import { EdoCloud } from './EdoCloud';

enum RaspberryErrorCode {
  noRaspberryFound = 1,
  tooManyRaspberryConnected = 2,
}

export enum LockState {
  ok = 'ok',
  death = 'death',
  occupied = 'occupied',
}

//TODO: rivedere messaggi
const codeToMessageMap = new Map([
  [RaspberryErrorCode.noRaspberryFound, 'Nessun edo disponibile'],
  [RaspberryErrorCode.tooManyRaspberryConnected, 'Too many edo to choose'],
]);

export type RaspberryDoc = {
  ip: number;
  hostname: string;
  lock: LockState;
} & EdoCloud;

//TODO: manage better? study better firebase 9 implementation.
export const raspberryConverter: FirestoreDataConverter<RaspberryDoc> = {
  toFirestore: (raspberry: RaspberryDoc) => ({...raspberry}),
  fromFirestore: (snapshot, options) => {
    const raspberry = snapshot.data(options) as RaspberryDoc;
    return {...raspberry};
  }
};

export const getErrorMessage = (code: RaspberryErrorCode) => codeToMessageMap.get(code) ?? `Code: ${code}`;
